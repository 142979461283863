<template>
  <div class="box">
    <div class="columns is-multiline is-mobile">
      <div class="column is-6 label">
        Producto
      </div>
      <div class="column is-6 value">
        {{detail.productName}}
      </div>
      <div class="column is-3 label">
        Usuario reclamo
      </div>
      <div class="column is-3 value">
        {{detail.username}}
      </div>
      <div class="column is-3 label">
        Estatus
      </div>
      <div class="column is-3 value">
        {{detail.status}}
      </div>
      <div class="column is-3 label">
        Fecha Reclamo
      </div>
      <div class="column is-3 value">
        {{formatDateTimestamp(detail.created_at)}}
      </div>
      <div class="column is-3 label">
        Precio
      </div>
      <div class="column is-3 value">
        {{detail.price}}
      </div>
      <div class="column is-3 label">
        Fecha Venta
      </div>
      <div class="column is-3 value">
        {{formatDateTimestamp(detail.sellDate)}}
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../mixin/validateDate'
export default {
  name: 'ClaimDetail',
  mixins: [mixin],
  props: {
    detail: {
      type: Object
    }
  }
}
</script>

<style scoped>
  .label {
    font-weight: bold;
    text-align: right;
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .value {
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
</style>
